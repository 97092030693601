var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"add-price"},[_c('el-form-item',{attrs:{"label":"商品规格","required":""}},[_c('el-radio-group',{model:{value:(_vm.value.spec_type),callback:function ($$v) {_vm.$set(_vm.value, "spec_type", $$v)},expression:"value.spec_type"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("单规格")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("多规格")])],1)],1),_c('specification',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.spec_type == 2),expression:"value.spec_type == 2"}],model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.spec_type == 1),expression:"value.spec_type == 1"}]},[_c('el-form-item',{attrs:{"label":"商品价格","required":"","prop":"spec_value_list[0].sell_price_old","rules":[
                    {
                        required: true,
                        message: '请输入商品金额',
                        trigger: ['blur', 'change']
                    }
                ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.sell_price_old),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "sell_price_old", $$v)},expression:"value.specs_single.sell_price_old"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"划线价","prop":"spec_value_list[0].lineation_price"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.lineation_price),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "lineation_price", $$v)},expression:"value.specs_single.lineation_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"成本价"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.cost_price),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "cost_price", $$v)},expression:"value.specs_single.cost_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"材质描述"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"text"},model:{value:(_vm.value.specs_single.caizhimiaoshu),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "caizhimiaoshu", $$v)},expression:"value.specs_single.caizhimiaoshu"}})],1),_c('el-form-item',{attrs:{"label":"现货库存","required":"","prop":"spec_value_list[0].stock","rules":[
                    {
                        required: true,
                        message: '请输入现货库存',
                        trigger: ['blur', 'change']
                    }
                ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.stock),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "stock", $$v)},expression:"value.specs_single.stock"}})],1),_c('el-form-item',{attrs:{"label":"生产库存","required":"","prop":"spec_value_list[0].stock_yield","rules":[
			        {
			            required: true,
			            message: '请输入生产库存',
			            trigger: ['blur', 'change']
			        }
			    ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.stock_yield),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "stock_yield", $$v)},expression:"value.specs_single.stock_yield"}})],1),_c('el-form-item',{attrs:{"label":"体积"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.volume),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "volume", $$v)},expression:"value.specs_single.volume"}})],1),_c('el-form-item',{attrs:{"label":"重量"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.weight),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "weight", $$v)},expression:"value.specs_single.weight"}})],1),_c('el-form-item',{attrs:{"label":"条码"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.bar_code),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "bar_code", $$v)},expression:"value.specs_single.bar_code"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }